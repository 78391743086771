
body {
    font-family: "Century Gothic", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.hic-font-12 {
    font-size: 12px;
}

.hic-font-14 {
    font-size: 14px;
}

.hic-font-18 {
    font-size: 18px;
}

.hic-font-20 {
    font-size: 20px;
}

.hic-font-22 {
    font-size: 22px;
}

.hic-font-24 {
    font-size: 24px;
}

.hic-font-30 {
    font-size: 30px;
}

.hic-font-bold {
    font-weight: 700;
}

.hic-font-color {
    color: rgb(77, 116, 149);
}

.hic-indent-left {
    margin-left: 30px;
}

.hic-margin-top-20 {
    margin-top: 20px;
}

.hic-margin-top-10 {
    margin-top: 10px;
}

.hic-margin-right-20 {
    margin-right: 20px;
}

.hic-margin-left-10 {
    margin-left: 10px;
}

.hic-margin-left-20 {
    margin-left: 20px;
}

.hic-margin-auto {
    margin: auto;
}

.homepage-root {
    width: 1000px;
    margin: auto;
}

.homepage-appointment-bar {
    min-height: 40px;
    text-align: center;
    display: grid;
    align-content: center;
    justify-content: center;
}

.menu-hic-responsive {
    display: flex;
}

.homepage-hyphen {
    margin-left: 5px;
    margin-right: 5px;
}

.hic-reponsive-display {
    display: flex;
}

@media only screen and (max-width: 1000px) {

    .homepage-root {
        width: inherit;
    }

    .contact-us-root {
        width: 90%;
    }
}

@media only screen and (max-width: 700px) {

    .hic-responsive-off {
        display: none;
    }

    .menu-hic-responsive {
        font-size: 12px;
        display: inline;
    }

    .homepage-hyphen {
        display: none;
    }

    .homepage-appointment-bar {
        display: inline;
    }

    .hic-font-20 {
        font-size: 16px;
    }

    .hic-font-18 {
        font-size: 14px;
    }

    .hic-reponsive-display {
        display: inline;
        padding-top: 20px;
    }
}