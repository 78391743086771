
.buttonbar-responsive {
    display: none;
}

@media only screen and (max-width: 800px) {

    .buttonbar-nonresponsive {
        display:none;
    }

    .buttonbar-responsive {
        display: inherit;
        margin-top: 20px;
    }
}