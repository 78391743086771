
.admin-page-root {
    max-width: 1000px;
    margin: auto;
    /*justify-content: center;*/
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 400px;
}

.admin-page-column-container {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-basis: 50%;
}

.admin-page-column {
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 350px;
}

.admin-page-header {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    color: #4D7495;
}

.admin-page-value {
    font-size: 12px;
    color: #4D7495;
}

.admin-page-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    border-bottom: solid 1px #4D7495;
    color: #4D7495;
}

@media only screen and (max-width: 850px) {
    .admin-page-root {
        display: block;
    }
}