
.header-root {
    display: flex;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
}

.header-left {
    flex-grow: 5.0;
    min-width: 400px
}

.header-right {
    flex-grow: 5.0;
    min-width: 400px;
    text-align: right;
    margin-right: 10px;
}

.header-icon-margins {
    margin-left: 8px;
    margin-right: 8px;
}

.header-contact-schedule {
    margin-top: 10px;
}

.schedule-appointment-title {

}

.header-icon-buffer-margin {
    margin-top: 15px;
}

.header-hic-logo {
    width: 400px;
}

@media only screen and (max-width: 1000px){
    .header-root {

    }

    .header-left {
        min-width: 300px;
    }

    .header-right {
        min-width: 200px;
    }

    .header-hic-logo {
        width: 300px;
    }
}

@media only screen and (max-width: 700px){
    .header-root {
        display: inline-block;
    }

    .header-left {
        text-align: center;
    }

    .header-right {
        text-align: center;
    }

    .header-logos {

    }

    .header-hic-logo {
        width: 300px;
    }

    .hic-header-font {
        font-size: 14px;
    }

    .header-hic-external-logo {
        width: 20px;
    }

    .header-hic-responsive {
        display: none;
    }
}