.footer-root {
    display: flex;
    background-color: #363636;
    height: 90px;
}

.footer-creia {
    flex-grow: 1.0;
    margin-left: 20px;
    margin-top: 20px;
}

.footer-menuitems {
    flex-grow: 5.0;
}

.footer-logo {
    flex-grow: 3.0;
    text-align: right;
    margin-right: 30px;
}

.footer-menuitem {
    color: white;
    margin-right: 20px;
}

.footer-menuitem:hover {
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 800px) {


    .footer-menuitems {
        display: none;
    }
}