
.menu-bar {
    display: flex;
}

.menu-bar-responsive {
    display: none;
}

.menu-item {
    flex-grow: 1.0;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
}

@media only screen and (max-width: 1000px) {
    .hic-font-14 {
        font-size: 12px;
    }
}

@media only screen and (max-width: 850px) {
    .hic-font-14 {
        font-size: 10px;
    }
}

@media only screen and (max-width: 750px) {

    .menu-bar {
        display: none;
    }

    .menu-bar-responsive {
        display: inherit;
        text-align: right;
        float: right;
    }
}