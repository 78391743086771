
.contact-us-root {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    padding-bottom: 30px;
    width: 100%;
}

.contact-us-responsive {
    display: flex;
}

.contact-us-title {
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    font-weight: 700;
    text-align: center;
}

.contact-us-form {
    text-align: left;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 350px;
}

.contact-us-field {
    width: 100%;
}

.contact-us-field-title {
    font-weight: bold;
}

.contact-us-field-container {
    margin-top: 20px;
}

.contact-us-form-complete {
    width: 300px;
    margin: auto;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .contact-us-responsive {
        display: none;
    }
}