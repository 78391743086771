
.order-inspection-root {
    margin: auto;
    padding-top: 20px;
    display: grid;
    width: 99.9%;
    /*border: solid 1px #4D7495;*/
}

.order-inspection-paper {
    min-width: 350px;
    margin-left: 10px;
    margin-right: 10px;
}

.order-inspection-container {
    display: flex;
    margin: auto;
}

.order-inspection-maxwidth {
    margin: auto;
    min-width: 800px;
    max-width: 800px;
}

@media only screen and (max-width: 850px) {

    .order-inspection-root {
        width: inherit !important;
        margin: initial;
        display: flow;
    }

    .order-inspection-container {
        display: inline;
        margin: auto;
        max-width: 550px;
        min-width: 550px;
        text-align: center;
    }

    .order-inspection-maxwidth {
        min-width: unset;
        max-width: unset;
    }

    .order-inspection-small {
        font-size: 24px;
    }

    .order-inspection-responsive {

    }
}

@media only screen and (max-width: 600px)
{
    .order-inspection-responsive {
        font-size: 16px;
    }
}

@media only screen and (max-width: 450px)
{
    .order-inspection-small {
        font-size: 16px;
    }

    .order-inspection {
        /*margin: unset;*/
    }

    .order-inspection-root {
        font-size: 12px;
    }

    .order-inspection-responsive {
        font-size: 14px;
    }
}